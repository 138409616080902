import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import DiscountBlok from '#app/components/storyblok/discount'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import { type loader } from '#app/routes/_public+/_layout'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import NoCurrentDiscountsLive from './discounts/no-current-discounts-live'
import TopNavigationDiscount from './discounts/top-navigation-discount'
import MobileNavFooter from './footer/mobile-nav-footer'
import ScrollableContent from './scrollable-content'
import { SlideContent, SlideTrigger } from './slides'

// Define the necessary props for DiscountTrigger to pass down to TopNavigation
interface DiscountTriggerProps {
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
	navigationData: NavigationCategoriesStoryblok[]
}

const DiscountTrigger = ({
	selectedCategory,
	onCategoryClick,
	navigationData,
}: DiscountTriggerProps) => {
	const { t } = useTranslation('navbar')
	const { discounts } = useLoaderData<typeof loader>()

	// Get the current time
	const now = new Date()

	// Filter discounts to check if there's any valid discount with start time in the past and end time in the future
	const validDiscounts = (discounts || []).filter(discount => {
		const { startTimeForDiscount, endTimeForDiscount } = discount.content

		// Convert to date objectsFany
		const startTime = new Date(startTimeForDiscount)
		const endTime = new Date(endTimeForDiscount)

		// Check if the discount is current (start time in the past, end time in the future)
		return startTime <= now && endTime >= now
	})

	// Only show DiscountBlok if there are valid discounts
	const hasValidDiscounts = validDiscounts.length > 0

	return (
		<div>
			<SlideTrigger
				slideKey="discounts"
				className="flex flex-row items-center justify-between rounded bg-beige-50 bg-ampersandDiscountsMobile bg-no-repeat p-3 "
			>
				<div className="relative w-fit">
					<PlentyButtonsAndLinks as="nav-md" className={cn(' text-black-90')}>
						{t('active_discounts')}
						{validDiscounts.length > 0 && (
							<span className="absolute -right-1 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center self-center rounded-[32px] bg-mint-green-80p px-3 py-1 text-center text-xs font-bold leading-none text-black-90">
								{validDiscounts.length}
							</span>
						)}
					</PlentyButtonsAndLinks>
				</div>

				<Icon name="right-arrow" size="lg" />
			</SlideTrigger>
			<SlideContent className="flex flex-col gap-3" slideKey="discounts">
				<ScrollableContent className="h-auto space-y-3">
					{/* Use TopNavigation inside the SlideContent */}
					<TopNavigationDiscount
						selectedCategory={selectedCategory}
						onCategoryClick={onCategoryClick}
						navigationData={navigationData}
					/>
					{hasValidDiscounts ? (
						<DiscountBlok />
					) : (
						<NoCurrentDiscountsLive
							selectedCategory={selectedCategory}
							_uid={''}
							component={'discount'}
						/>
					)}
					<div className="flex pt-5">
						<MobileNavFooter />´
					</div>
				</ScrollableContent>
			</SlideContent>
		</div>
	)
}

export default DiscountTrigger
