import { Link } from '@remix-run/react'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SearchNoResults from '#app/components/storyblok/search-no-result'
import ButtonAndLinks from '#app/components/typography/button-and-links'
import PlentyBody from '#app/components/typography/plenty-body'
import { GiantLoadingSpinner } from '#app/components/ui/giant-spinner'
import { Icon } from '#app/components/ui/icon'
import ClerkProductCard from '#app/features/plp-clerk/clerk-components/clerk-product-card/clerk-product-card'
import ProductCardSkeleton from '#app/features/plp-clerk/clerk-components/clerk-product-card/clerk-skeleton-product-card'
import { useClerkVisitorStore } from '#app/store/clerk-visitor'
import { useDebounceSearch } from '#app/utils/misc.ts'
import { capitalizeWords } from '#app/utils/string-utils'
import { trackProductClicked } from '#app/utils/tracking/amplitude/product-card-clicked'
import { logClickEvent } from '#app/utils/tracking/clerk-tracking'
import { type ClerkProduct } from '#types/product'
import SearchGenderFilter from './search-gender-filter'
import NoResultFound from './search-no-result'

interface PredictiveSearchGridProps {
	searchTerm: string
	genderFilter: string
	closeOverlay: () => void
	updateHits: (hits: number) => void
	updateProductDataLength: (length: number) => void
	onGenderFilterChange: (newFilter: string) => void
	updateBrands: (brands: string[]) => void
	updateProductNames: (names: string[]) => void
	updatePageSearchUrl: (url: any) => void
}

interface ApiResponse {
	status: string
	result: ClerkProduct[]
	estimated_total_count: number
	query: string
	facets: any
	brands: string[]
	pageSearchUrl: {
		estimated_total_count: number
	}
}

export default function PredictiveSearchGrid({
	searchTerm,
	genderFilter,
	closeOverlay,
	updateHits,
	updateProductDataLength,
	onGenderFilterChange,
	updateBrands,
	updateProductNames,
	updatePageSearchUrl,
}: PredictiveSearchGridProps) {
	const [data, setData] = useState<ApiResponse | null>(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<string | null>(null)
	const [isNavigating, setIsNavigating] = useState(false)
	const [showNoResults, setShowNoResults] = useState(false)
	const prevSearchTerm = useRef(searchTerm)
	const noResultsRef = useRef(false)
	const { clerkVisitor } = useClerkVisitorStore()

	let { t } = useTranslation('search')
	const debouncedSearchTerm = useDebounceSearch(searchTerm, 400)

	useEffect(() => {
		async function fetchData() {
			if (!searchTerm) return

			if (
				noResultsRef.current &&
				searchTerm.length > prevSearchTerm.current.length
			) {
				return
			}

			let apiUrl = `/resources/clerkPredictive?query=${encodeURIComponent(
				searchTerm,
			)}`
			if (genderFilter && genderFilter !== 'all') {
				apiUrl += `&gender=${encodeURIComponent(genderFilter)}`
			}

			setLoading(true)
			setShowNoResults(false)

			try {
				const response = await fetch(apiUrl)
				if (!response.ok) throw new Error('Network response was not ok')
				const data: ApiResponse = await response.json()

				// Check if data.result exists
				if (!data.result) {
					throw new Error('Invalid data from server: result is missing')
				}

				setData(data)
				updateHits(data.estimated_total_count)
				updateBrands(data.brands)
				updateProductDataLength(data.result.length)
				updateProductNames(data.result.map(product => product.name))
				updatePageSearchUrl(data.pageSearchUrl)
				noResultsRef.current = data.estimated_total_count === 0
				if (data.estimated_total_count === 0 && searchTerm.length > 1) {
					onGenderFilterChange('all')
				}
				if (
					data.result.length === 0 &&
					data.pageSearchUrl.estimated_total_count === 0
				) {
					setShowNoResults(true)
				}
			} catch (error: any) {
				setError(error.message)
				setData(null) // Set data to null when there's an error
				updateHits(0)
				updateProductDataLength(0)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
		prevSearchTerm.current = searchTerm
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchTerm, genderFilter, updateBrands])

	const handleProductClick = () => {
		setIsNavigating(true)
		setTimeout(closeOverlay, 400)
	}

	const handleProductClickPDP = (id: string, index: number) => {
		trackProductClicked(index, searchTerm)
		logClickEvent(id, index, clerkVisitor!)

		setIsNavigating(true)
		setTimeout(closeOverlay, 400)
	}
	const handleGenderFilterChange = (newFilter: string) => {
		onGenderFilterChange(newFilter)
		if (data?.estimated_total_count === 0 && searchTerm.length > 1) {
			onGenderFilterChange('all')
		}
	}

	if (error) return <div>Error: {error}</div>
	if (!data || !data.result) return <div>No results available</div>
	if (isNavigating) {
		return (
			<div className="flex items-center justify-center">
				<GiantLoadingSpinner />
			</div>
		)
	}
	const EIGHT_DIGIT_REGEX = /^\d{8}$/
	const hasResults = data && data.result && data.result.length > 0

	return (
		<>
			{searchTerm.length > 0 &&
				(loading ? (
					<div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
						{Array.from({ length: 4 }, (_, index) => (
							<ProductCardSkeleton key={index} />
						))}
					</div>
				) : hasResults ? (
					<div>
						<div className="mb-2 flex items-center justify-between">
							<PlentyBody size="md" className="text-beige-100">
								{searchTerm.match(EIGHT_DIGIT_REGEX)
									? t('search_found_products', {
											count: data.estimated_total_count,
									  })
									: t('search_products')}
							</PlentyBody>
							{!searchTerm.match(EIGHT_DIGIT_REGEX) && (
								<div className="flex justify-start  md:hidden">
									<Link
										to={`/clerk-search?query=${searchTerm}${
											genderFilter === 'all'
												? ''
												: '&category=' + capitalizeWords(genderFilter)
										}`}
										onClick={handleProductClick}
									>
										<div className="z-10 flex items-start gap-[4px]">
											<ButtonAndLinks
												as="span"
												body={t('search_see_all_products', {
													count: data.estimated_total_count,
												})}
											/>
											<Icon
												className="text-body-md h-4 w-4"
												name="right-arrow"
											/>
										</div>
										<div className="-mt-2 rounded-lg border-4 border-beige-50" />
									</Link>
								</div>
							)}
						</div>

						<div className="flex flex-col gap-2">
							<div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
								{data.result &&
									data.result.map((product, index) => (
										<Link
											key={index}
											to={`/product/${product.style_id}`}
											onClick={() => handleProductClickPDP(product.id, index)}
										>
											<ClerkProductCard product={product} />
										</Link>
									))}
							</div>

							{!searchTerm.match(EIGHT_DIGIT_REGEX) && (
								<div className="hidden justify-start md:flex">
									<Link
										to={`/clerk-search?query=${searchTerm}&category=${genderFilter}`}
										onClick={handleProductClick}
									>
										<div className="z-10 flex items-start gap-[4px]">
											<ButtonAndLinks
												as="span"
												body={t('search_see_all_products', {
													count: data.estimated_total_count,
												})}
											/>
											<Icon
												className="text-body-md h-4 w-4"
												name="right-arrow"
											/>
										</div>
										<div className="-mt-2 rounded-lg border-4 border-beige-50" />
									</Link>
								</div>
							)}
						</div>
					</div>
				) : (
					showNoResults && (
						<div className="flex w-full flex-col gap-4 overflow-auto scrollbar-hide md:grid md:grid-cols-3">
							<div className="flex flex-col gap-4 md:col-span-1">
								<SearchGenderFilter
									activeFilter={genderFilter}
									onFilterChange={handleGenderFilterChange}
								/>
								<NoResultFound searchTerm={searchTerm} />
							</div>
							<div className="md:col-span-2">
								<SearchNoResults />
							</div>
						</div>
					)
				))}
		</>
	)
}
