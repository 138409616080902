import { useFetcher } from '@remix-run/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
// import { LoadingSpinner } from '#app/components/ui/spinner'

// interface ICategory {
// 	id: string
// 	name: string
// 	url: string
// }

// interface ICategoryData {
// 	categories: ICategory[]
// }

// interface IPopularSearches {
// 	result: string[]
// 	status: string
// }

// interface IPopularSearchesData {
// 	popularSearches: IPopularSearches
// 	categories: ICategoryData
// }

interface PopularGridSuggestionsProps {
	onSuggestionClick: (suggestion: string) => void
}

const PopularGridSuggestions = ({
	onSuggestionClick,
}: PopularGridSuggestionsProps) => {
	const fetcher = useFetcher()

	let { t } = useTranslation('search')

	useEffect(() => {
		fetcher.submit(
			{},
			{ method: 'POST', action: '/resources/clerkPopularSuggestion' },
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleSuggestionClick = (suggestion: string) => {
		onSuggestionClick(suggestion)
	}

	// if (fetcher.state !== 'idle') return <LoadingSpinner />
	// if (!fetcher.data) return <div>No results available</div>

	// const { popularSearches } = fetcher.data as IPopularSearchesData
	const placeholderSuggestions = [
		t('search_popular_placeholder_1'),
		t('search_popular_placeholder_2'),
		t('search_popular_placeholder_3'),
		t('search_popular_placeholder_4'),
	]

	return (
		<div className="flex flex-col gap-2">
			<PlentyButtonsAndLinks as="nav-sm">
				{t('search_popular')}
			</PlentyButtonsAndLinks>
			<div className="flex flex-col gap-2 ">
				{/* {popularSearches.result.length > 0
					? popularSearches.result.map((suggestion, index) => (
							<div
								key={index}
								className="flex cursor-pointer justify-between gap-3 px-[4px] py-2 hover:bg-beige-50"
								onClick={() => handleSuggestionClick(suggestion)}
							>
								<PlentyBody size="sm" className="cursor-pointer">
									{suggestion}
								</PlentyBody>
								<Icon className="text-body-md h-6 w-6" name="trending-up" />
							</div>
					  ))
					:  */}
				{placeholderSuggestions.map((suggestion, index) => (
					<div
						key={index}
						className="flex cursor-pointer justify-between gap-2 hover:bg-beige-50"
						onClick={() => handleSuggestionClick(suggestion)}
					>
						<PlentyButtonsAndLinks
							as="nav-sm"
							key={index}
							className="cursor-pointer"
						>
							{suggestion}
						</PlentyButtonsAndLinks>
						<Icon className="text-body-md " size="sm" name="trending-up" />
					</div>
				))}
			</div>
		</div>
	)
}

export default PopularGridSuggestions
