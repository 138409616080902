import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc'
import { type DiscountStoryblok } from '#types/component-types-sb'
import DiscountInformation from './discount-information'
import DiscountLocationAndAudience from './discount-location-and-audience'
import DiscountImageAndImageText from './image-and-image-text'
const Discount = ({ discount }: DiscountStoryblok) => {
	// Dynamically set the width based on `fullWidth`
	return (
		<div className={`flex w-full flex-col rounded-[8px] bg-white`}>
			<div
				className={cn(
					'flex flex-col rounded-[8px] border border-beige-70 bg-white',
					discount.storeDiscount ? 'gap-5' : 'gap-2', // Conditional gap class
				)}
			>
				<Link to={discount.link.url}>
					<DiscountImageAndImageText
						discount={discount}
						_uid={discount._uid}
						component={'discount'}
					/>
				</Link>
				<div className="flex flex-col gap-2 px-2 pb-2">
					<DiscountInformation discount={discount} />
					{discount.storeDiscount && (
						<DiscountLocationAndAudience discount={discount} />
					)}{' '}
				</div>
			</div>
		</div>
	)
}

export default Discount
