import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { type loader } from '#app/routes/_public+/_layout'
import Discount from '../navigation/mobile-navbar/discounts'
import PlentyButtonsAndLinks from '../typography/plenty-buttons-and-links'

const DiscountBlok = () => {
	const { discounts } = useLoaderData<typeof loader>()
	const { t } = useTranslation('navbar')

	// Get the current date
	const now = new Date()

	// Filter personal discounts based on storeDiscount flag and valid time span
	const personalDiscounts = discounts.filter(discount => {
		const { endTimeForDiscount, storeDiscount } = discount.content
		const endTime = new Date(endTimeForDiscount)
		return !storeDiscount && endTime >= now
	})

	// Filter store discounts based on storeDiscount flag and valid time span
	const storeDiscounts = discounts.filter(discount => {
		const { endTimeForDiscount, storeDiscount } = discount.content
		const endTime = new Date(endTimeForDiscount)
		return storeDiscount && endTime >= now
	})

	return (
		<div className="flex h-auto flex-col gap-4 bg-beige-50-opacity px-4 py-3">
			{/* Personal Offers Section */}
			{personalDiscounts.length > 0 && (
				<div className="flex flex-col gap-2">
					<PlentyButtonsAndLinks as="nav-sm">
						{t('personal_offer')}
					</PlentyButtonsAndLinks>
					{/* Grid layout for personal offers */}
					<div className="grid grid-cols-2 gap-2">
						{personalDiscounts.map((discount, index) => (
							<div
								key={index}
								className={
									discount.content.fullWidth ? 'col-span-2 w-full' : ''
								}
							>
								<Discount
									discount={discount.content}
									_uid={discount.uuid}
									component={'discount'}
								/>
							</div>
						))}
					</div>
				</div>
			)}

			{/* Store Discounts Section */}
			{storeDiscounts.length > 0 && (
				<div className="flex flex-col gap-2">
					<PlentyButtonsAndLinks as="nav-sm">
						{t('store_discounts')}
					</PlentyButtonsAndLinks>
					{/* Grid layout for store discounts */}
					<div className="grid grid-cols-2 gap-2">
						{storeDiscounts.map((discount, index) => (
							<div
								key={index}
								className={
									discount.content.fullWidth ? 'col-span-2 w-full' : ''
								}
							>
								<Discount
									discount={discount.content}
									_uid={discount.uuid}
									component={'discount'}
								/>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default DiscountBlok
